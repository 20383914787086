<template>
  <div id="app" ref="App">
    <div id="home">
      <Nav1 v-if="$options.components['Nav1'] !== undefined"/>
      <Banner v-if="$options.components['Banner'] !== undefined"/>
      <About v-if="$options.components['About'] !== undefined"/>
      <AboutMe v-if="$options.components['AboutMe'] !== undefined"/>
      <Team v-if="$options.components['Team'] !== undefined"/>
      <Qualifications v-if="$options.components['Qualifications'] !== undefined"/>
      <Pricing v-if="$options.components['Pricing'] !== undefined"/>
      <Faq v-if="$options.components['Faq'] !== undefined"/>
      <Contact v-if="$options.components['Contact'] !== undefined"/>
      <Footer v-if="$options.components['Footer'] !== undefined"/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {},
  metaInfo: {},
  created () {
    this.$webData.components.forEach((c) => {
      this.$options.components[c.name] = () => import(`./components/${c.name}.vue`);
    })
    this.$options.metaInfo = {
      title: this.$webData.meta.title,
      meta: [
        { name: 'keywords', content: this.$webData.meta.keywords },
        { name: 'description', content: this.$webData.meta.description }
      ]
    }
  },
  mounted () {
   /* this.$nextTick(() => {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    });*/
  }
}

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');

#app {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[v-cloak] { display: none; }
</style>
