import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueScrollactive from 'vue-scrollactive';
import App from './App.vue';
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./scss/app.scss";

Vue.use(VueMeta);
Vue.use(VueScrollactive);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.prototype.$portfolioName = process.env.VUE_APP_PORTFOLIO_NAME;
Vue.prototype.$webData = require(`./assets/json/${process.env.VUE_APP_PORTFOLIO_NAME}.json`);

new Vue({
  render: h => h(App),
}).$mount('#app')

/*fetch(`content/${process.env.VUE_APP_PORTFOLIO_NAME}/${process.env.VUE_APP_PORTFOLIO_NAME}.json`)
  .then((response) => {
    return response.json();
  })
  .then((data) => {


    new Vue({
      render: h => h(App),
    }).$mount('#app')
  })*/

